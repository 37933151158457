<template>
  <div class="store">
    <Loading v-if="isLoading" />
    <div v-else class="flex">
      <!-- <div v-for="(item, index) in order_list" :key="index"></div> -->
      <div class="flex">
        <div class="store-info">
          <!-- <input class="follow-handle" type="checkbox" /> -->
          <div class="cancel-follow">
            <span>{{ $t('button.unfollow') }}</span>
          </div>

          <div class="store-logo">
            <div class="store-logo-img">
              <img src="../../../assets/order/tmp/store-icon.jpg" />
            </div>
          </div>

          <div class="store-name">
            <span>环标科创标物旗舰店</span>
          </div>

          <div class="store-attr">
            <div class="store-attr-lv">
              <span>人气店铺</span>
            </div>
            |
            <div class="store-attr-tiem">
              <span>1分钟前关注</span>
            </div>
          </div>

          <div class="store-button">
            <button class="store-in">进入店铺</button>
            <button class="store-cs">{{ $t('button.Contact_customer_service') }}</button>
          </div>
        </div>
        <div class="store-wares">
          <div class="top">
            <div class="menu">
              <div class="qiehuan">
                <div
                  class="daohang"
                  :class="[
                    { bian: index == TabIndex },
                    { 'border-right-line': index != TabList.length - 1 },
                  ]"
                  v-for="(item, index) in paihang"
                  :key="index"
                  @click="selectedTabItem(index, item.id)"
                >
                  {{ item.title }}
                </div>
              </div>
              <div class="piliang">更多<i class="el-icon-arrow-right"></i></div>
            </div>
          </div>

          <div class="store-wares-content">
            <div class="wares-item" v-if="TabIndex == 0">
              <div class="wares-item-img">
                <img src="../../../assets/details/tmp/have_look_001.png" />
              </div>
              <div class="wares-item-title">
                <div class="wares-item-title-text">
                  <span>菜籽油/脂肪酸标准 嘻嘻嘻嘻嘻嘻嘻</span>
                </div>
                <div class="wares-item-title-mony">
                  <span>320.00</span>
                </div>
              </div>
            </div>
            <div class="wares-item" v-if="TabIndex == 1">
              <div class="wares-item-img">
                <img src="../../../assets/details/tmp/have_look_001.png" />
              </div>
              <div class="wares-item-title">
                <div class="wares-item-title-text">
                  <span>菜籽油</span>
                </div>
                <div class="wares-item-title-mony">
                  <span>120.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      order_list: [],
      isLoading: true,
      pages: 1,
      mes: '',
      paihang: [
        {
          id: 1,
          title: '热销',
        },
        {
          id: 2,
          title: '新品',
        },
      ],
      TabIndex: 0,
      TabList: [],
    };
  },
  created() {
    this.getorder();
  },
  methods: {
    getorder() {
      get('api/collectStore/selCollectStoreList').then((res) => {
        this.order_list = res.data.data;
        this.isLoading = false;
      });
    },
    selectedTabItem(index, e) {
      this.TabIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  width: 100%;
  height: 100%;
}
.store {
  width: 100%;
  height: 228px;
  display: inline-flex;

  .store-info {
    width: 232px;
    height: 100%;
    border: 1px solid #c3c3c3;
    position: relative;

    &:hover {
      .cancel-follow {
        display: inline-block;
      }

      .follow-handle {
        display: inline-block;
      }
    }

    .follow-handle {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;

      &:checked {
        display: inline-block;
      }
    }

    .cancel-follow {
      display: none;
      position: absolute;
      width: 76px;
      height: 24px;
      right: 0px;
      top: 0px;
      background: #b0aeaf;
      border-radius: 0px 0px 0px 6px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      z-index: 2;
      cursor: pointer;
      line-height: 24px;
    }

    .store-logo {
      width: 100%;
      height: 113px;
      position: relative;

      .store-logo-img {
        width: 130px;
        height: 50px;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .store-name {
      font-size: 16px;
      font-weight: 600;
      height: 20px;
      width: 100%;
      text-align: center;
      line-height: 20px;
    }

    .store-attr {
      display: inline-flex;
      flex-direction: row;
      font-size: 12px;
      width: 163px;
      margin: 0px 34px;
      justify-content: center;

      .store-attr-tpye {
        color: #000;
      }

      .store-attr-tiem,
      .store-attr-lv {
        color: #989898;
      }

      .store-attr-lv {
        margin-right: 9px;
      }

      .store-attr-tiem {
        margin-left: 9px;
      }
    }

    .store-evaluate {
      height: 30px;
      width: 100%;

      .store-score {
        height: 100%;
        width: 100%;

        text {
          fill: #8b8b8b;
        }

        image[class*='active-img'] {
          display: none;
        }

        @for $i from 1 through 5 {
          &.active-#{$i} {
            @for $k from 1 through $i {
              .active-img-#{$k} {
                display: inline-block;
              }
            }
          }
        }

        .score {
          font-size: 14px;
        }
      }
    }

    .store-button {
      width: 100%;
      height: 44px;
      display: inline-flex;
      position: absolute;
      bottom: 0;
      left: 0;
      .store-in,
      .store-cs {
        width: 116.5px;
        height: 100%;
        border: 0px;
        background: #f5f5f5;
        border-top: 1px solid #d1d1d1;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;

        &:focus {
          outline: none;
        }

        color: #777777;

        &:before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background: transparent no-repeat;
          background-size: 100% 100%;
          margin-top: 3px;
          margin-right: 5px;
        }
      }

      .store-in {
        &::before {
          background-image: url('../../../assets/order/store-in.png');
        }
      }

      .store-cs {
        &::before {
          background-image: url('../../../assets/order/store-cs.png');
        }

        border-right: 0px;
      }
    }
  }

  .store-wares {
    height: 100%;
    flex: 100;
    border: 1px solid #dddddd;
    border-left: 0px;
    padding: 0px 20px;

    .top {
      .menu {
        height: 30px;
        width: 100%;
        margin: 13px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .qiehuan {
          height: 80px;
          display: flex;
          align-items: center;
          cursor: default;
          .daohang {
            font-size: 15px;
            color: #272727;
            margin-right: 20px;
            cursor: pointer;
          }
          .bian {
            color: #1a4fff;
            border: 1px solid #1a4fff;
            padding: 0 3px;
            font-size: 14px;
          }
        }
        .piliang {
          cursor: pointer;
          font-size: 14px;
          color: #333;
        }
      }
    }

    .store-wares-content {
      width: 100%;
      height: 168px;
      display: grid;
      justify-content: space-around;
      grid-template-columns: repeat(auto-fill, 133px);
      grid-gap: 10px 0px;

      .wares-item {
        border: #e2ecf6 2px solid;
        display: inline-block;
        width: 133px;
        height: 160px;

        &-img {
          height: 80px;
          padding: 0px 35px;
          padding-top: 5px;
          background: #e2ecf6;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        &-title {
          height: 78.5px;

          &-text {
            width: 92%;
            margin: 10px auto;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }

          &-mony {
            color: #e58426;
            width: 95%;
            margin: auto;
            font-weight: 700;

            &:before {
              content: '￥';
            }
          }
        }
      }
    }
  }
}
</style>
